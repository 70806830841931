<template>
  <div>
    <nuxt-page></nuxt-page>
  </div>
</template>

<script>
import TrailingSlashRedirect from "~/middleware/trailing-slash-redirect.js";

export default {
  name: "PagePreventionCategoryIndex",
  setup() {
    defineI18nRoute({
      paths: {
        fr: "/je-previens/[category]",
      },
    });
  },
  middleware: [TrailingSlashRedirect],
};
</script>
